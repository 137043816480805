import axios from 'axios';
import moment from 'moment';
import config from 'react-global-configuration';
import ZNotFound from '../system/ZNotFound';
import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Container, Typography } from '@material-ui/core';
import { Isset, Formatter } from '../../utils';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { RiMoneyDollarCircleLine, RiCalendarTodoFill } from 'react-icons/ri';
import 'react-credit-cards/es/styles-compiled.css';
import { Mask } from '../../utils';
import Boleto from 'boleto.js';
import parse from 'html-react-parser';
import itau from '../../assets/boleto/Itau_logo.png';
import bradesco from '../../assets/boleto/Bradesco_logo.png';
import QRCode from 'qrcode.react';
import saveSvgAsPng from 'save-svg-as-png';

const useStyles = makeStyles(theme => ({
	main: {
		position: 'relative',
		maxHeight: '3500px',
		width: '100%',
	},
	avatar: {
		cursor: 'pointer',
		margin: 4,
		marginRight: 15,
		width: 140,
		maxHeight: 50,
		objectFit: 'contain',
	},
	textBold: {
		fontWeight: 'bold',
		fontSize: '14px',
	},
	textTop: {
		fontWeight: 'bold',
		fontSize: '14px',
	},
	card: {
		background: '#f2f2f2',
		borderRadius: '8px',
		fontSize: '12px',
		padding: '16px',
		// width:,
		maxWidth: '400px',
		// marginBottom: '16px',
		display: 'flex',
		maxHeight: '77px ',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '16px',
		// width: '100%'
	},
	pix: {
		background: '#f2f2f2',
		borderRadius: '8px',
		fontSize: '12px',
		padding: '16px 8px',
		maxWidth: '400px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '162px',
		gap: '16px',
	},
	card2: {
		background: '#f2f2f2',
		borderRadius: '8px',
		fontSize: '12px',
		padding: '16px',
		// width:,
		margin: '16px 0 16px 0',
	},
	header: {
		background: '#fff',
		width: '912px',
		marginBottom: '-8px',
		padding: '32px 32px 0 32px',
	},
	line: {
		borderTop: '1px solid #000',
		height: '1px',
		margin: '16px 0 16px 0',
	},
	recibo: {
		border: '1px solid #000',
		width: '829px',
		height: '287px',
	},
	boleto: {
		border: '1px solid #000',
		width: '829px',
		height: '450px',
	},
	row1: {
		borderBottom: '1px solid #000',
		width: '829px',
		height: '46.5px',
		display: 'flex',
		alignItems: 'end',
		gap: '16px',
	},
	row1Coluna1: {
		borderRight: '1px solid #000',
		width: '156px',
		height: '35px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	row1Coluna2: {
		borderRight: '1px solid #000',
		width: '78px',
		height: '35px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 'bold',
		fontSize: '18px',
	},
	row1Coluna3: {
		width: '594px',
		height: '35px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '19.5px',
		fontWeight: 'bold',
	},
	row2: {
		borderBottom: '1px solid #000',
		width: '829px',
		height: '46.5px',
		display: 'flex',
		alignItems: 'end',
		gap: '16px',
	},
	barcode: { maxWidth: '800px', marginTop: '16px' },
	infosBeneficiario: {
		width: '583px',
		height: '120px',
		display: 'flex',
		flexDirection: 'column',
		borderRight: '1px solid #000',
	},
	rows: { height: '22.5px', width: '829px', border: '1px solid #fff' },
	qrcodeHover: {
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		opacity: 0,
		position: 'absolute',
		height: 80,
		width: 80,
		background: '#fafafa',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 100,
		top: 0,
		left: 0,
		'&:hover': {
			opacity: 0.7,
		},
	},
	hoverPixCopiaCola: {
		textAlign: 'center',
		padding: 10,
		border: 'solid 1px white',
		borderRadius: 10,
		cursor: 'pointer',
		'&:hover': {
			border: 'dashed 1px black',
		},
	},
	printButton: {
		backgroundColor: '#4a6682',
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			color: theme.palette.primary.main,
			backgroundColor: '#4a6682',
		},
	},
}));
export default function VerBoleto() {
	const token = useSelector(store => store.auth.token);
	const match = useRouteMatch();
	const [data, setData] = useState(null);
	const [dataZoop, setDataZoop] = useState(null);
	const [dataClient, setDataClient] = useState(null);
	const qrCodeRef = React.createRef();
	const classes = useStyles();

	const downloadQRCode = () => {
		const svg = qrCodeRef.current.getElementsByTagName('svg')[0];
		saveSvgAsPng.saveSvgAsPng(svg, 'qrcode.png', {
			scale: 5,
			encoderOptions: 1,
			backgroundColor: 'white',
		});
	};

	const getData = useCallback(async () => {
		let req = await axios.post(
			`${config.get('apiUrl')}vendas/` + match.params.vendaId + '/boleto',
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (req.data.success) {
			setDataZoop({
				...dataZoop,
				boletoZoop: req.data.boletoZoop,
				pedidoZoop: req.data.pedidoZoop,
			});
			setDataClient({
				...dataClient,
				cliente: req.data.cliente,
			});
			setData(req.data.venda);
		}
	}, [match.params.vendaId, token]);

	useEffect(() => {
		getData();
	}, [getData]);

	let code;
	let flag;
	let nossoNumero;
	let nrDocument;

	const GenerateBoleto = props => {
		const { codigoBarras } = props;
		const svg = new Boleto(Mask.Boleto(codigoBarras)).toSVG();

		return parse(svg);
	};

	if (Isset(() => dataZoop.pedidoZoop?.gateway_authorizer) && dataZoop.pedidoZoop?.gateway_authorizer === 'ITAU') {
		code = '341-7';
		flag = itau;
		// nossoNumero = `${dataZoop.pedidoZoop.payment_method.bank_code}/${dataZoop.boletoZoop.document_number}`;
		nossoNumero = `000/${dataZoop.boletoZoop.document_number}`;
		nrDocument = dataZoop.boletoZoop.document_number;
	}
	if (
		Isset(() => dataZoop.pedidoZoop?.gateway_authorizer) &&
		dataZoop.pedidoZoop?.gateway_authorizer === 'BRADESCO'
	) {
		code = '237-2';
		flag = bradesco;
		nossoNumero = `${dataZoop.pedidoZoop.payment_method.bank_code}/000${dataZoop.boletoZoop.document_number}`;
		nrDocument = `000${dataZoop.boletoZoop.document_number}`;
	}

	if (Isset(() => data.status.id) && data.status.id === 7) {
		return <ZNotFound text="Venda Em Processamento" />;
	}
	return (
		<>
			<Container maxWidth={'md'} id="section-to-print">
				<Grid container>
					<Grid item xs={12}>
						{data && data.tipoPagamento.boleto && (
							<Grid container direction="column" className={classes.main} id="pdf-content">
								<Grid item className={classes.header}>
									<Grid container direction="row" justifyContent="space-between">
										{data && data.estabelecimento.logo && (
											<Grid>
												<img
													src={data.estabelecimento.logo}
													alt={data.estabelecimento.nome}
													className={classes.avatar}
												/>
											</Grid>
										)}
										<Grid>
											<Typography className={classes.textTop}>
												{data.estabelecimento.nome}
											</Typography>
											<Typography>{data.estabelecimento.email}</Typography>
											<Typography>
												{Isset(() => data.estabelecimento.cnpj) &&
													Formatter.Cnpj(data.estabelecimento.cnpj)}
												{Isset(() => data.estabelecimento.cpf) &&
													Formatter.Cpf(data.estabelecimento.cpf)}
											</Typography>
										</Grid>
									</Grid>
									<div className={classes.line}></div>
									<Grid container justifyContent="space-between">
										<Grid style={{ width: '400px' }}>
											<Grid
												item
												className={classes.card}
												xs={12}
												sm={6}
												style={{ marginBottom: '8px' }}
											>
												<Grid>
													<RiCalendarTodoFill size={32} />
												</Grid>
												<Grid>
													<Typography className={classes.textBold}>
														Data Vencimento
													</Typography>
													<Typography>
														{Isset(() => data.tipoPagamento.boleto.data_vencimento)
															? moment(
																	data.tipoPagamento.boleto.data_vencimento,
																	'YYYY-MM-DD'
															  ).format('DD/MM/YYYY')
															: ''}
													</Typography>
												</Grid>
											</Grid>
											{dataZoop.boletoZoop.payment_type === 'bolepix' && (
												<Grid
													item
													className={classes.card}
													xs={12}
													sm={6}
													style={{ marginTop: '8px' }}
												>
													<Grid>
														<RiMoneyDollarCircleLine size={32} />
													</Grid>
													<Grid>
														<Typography className={classes.textBold}>Valor</Typography>
														<Typography> {Formatter.Real(data.valor)}</Typography>
													</Grid>
												</Grid>
											)}
										</Grid>
										{dataZoop.boletoZoop.payment_type === 'bolepix' ? (
											<Grid style={{ width: '400px' }}>
												<Grid item className={classes.pix} xs={12} sm={6}>
													<Grid>
														<Typography
															className={classes.textBold}
															style={{
																width: '400px',
																textAlign: 'center',
																marginBottom: '8px',
															}}
														>
															Pague com PIX
														</Typography>
														<Grid xs={12} md={3}>
															<div
																style={{
																	height: 100,
																	cursor: 'pointer',
																	width: '400px',
																	textAlign: 'center',
																}}
															>
																<QRCode
																	renderAs="svg"
																	value={dataZoop.boletoZoop.bolepix}
																	width={100}
																	height={100}
																/>
															</div>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										) : (
											<Grid
												item
												className={classes.card}
												xs={12}
												sm={6}
												style={{ marginBottom: '8px' }}
											>
												<Grid>
													<RiMoneyDollarCircleLine size={32} />
												</Grid>
												<Grid>
													<Typography className={classes.textBold}>Valor</Typography>
													<Typography> {Formatter.Real(data.valor)}</Typography>
												</Grid>
											</Grid>
										)}
									</Grid>
									{Isset(() => data.tipoPagamento.boleto.descricao) &&
										data.tipoPagamento.boleto.descricao &&
										data.tipoPagamento.boleto.descricao.length > 0 && (
											<Grid className={classes.card2}>
												<Typography className={classes.textBold}>Descrição</Typography>
												<Typography>{data.tipoPagamento.boleto.descricao}</Typography>
											</Grid>
										)}

									<Grid className={classes.card2}>
										<Typography className={classes.textBold}>Codigo de Barras</Typography>
										<Typography>{Mask.Boleto(data.tipoPagamento.boleto.codigo_barras)}</Typography>
									</Grid>
								</Grid>
								<Grid style={{ padding: '8px 0 32px 32px', backgroundColor: '#fff' }}>
									<Grid className={classes.recibo}>
										<Grid className={classes.row1}>
											<Grid className={classes.row1Coluna1}>
												{' '}
												<img alt="" height={25} src={flag} />
											</Grid>
											<Grid className={classes.row1Coluna2}>{code}</Grid>
											<Grid className={classes.row1Coluna3}>
												{Mask.Boleto(data.tipoPagamento.boleto.codigo_barras)}
											</Grid>
										</Grid>

										<Grid
											style={{
												height: '18px',
												width: '829px',
												padding: '4px 0 0 16px',
												fontSize: '7.5px',
											}}
										>
											Pagador/CPF/CNPJ/Endereço{' '}
										</Grid>
										<Grid
											style={{
												height: '22.5px',
												width: '829px',
												padding: '4px 0 0 16px',
												fontSize: '12px',
												fontWeight: 'bold',
											}}
										>
											{dataClient.cliente.nome.length > 29
												? dataClient.cliente.nome.slice(0, 30)
												: dataClient.cliente.nome}{' '}
											CPF/CNPJ: {dataClient.cliente.clientes_documentos[0].documento}
										</Grid>
										<Grid
											style={{
												height: '22.5px',
												width: '829px',
												padding: '0 3px 0 15px',
												fontSize: '12px',
												fontWeight: 'bold',
											}}
										>
											{' '}
											{dataClient.cliente.endereco.logradouro}{' '}
											{dataClient.cliente.endereco.numero}{' '}
											{dataClient.cliente.endereco.complemento &&
												dataClient.cliente.endereco.complemento}{' '}
											, CEP: {dataClient.cliente.endereco.cep}{' '}
											{dataClient.cliente.endereco.cidade}
										</Grid>
										<Grid
											style={{
												height: '16.5px',
												width: '811px',
												padding: '0 3px 0 15px',
												fontSize: '7.5px',
												borderBottom: '1px solid #000',
											}}
										>
											Pagador/Avalista:
										</Grid>

										<Grid
											style={{
												height: '16.5px',
												width: '829px',
												fontSize: '7.5px',
												display: 'flex',
											}}
										>
											<Grid
												style={{
													width: '219px',
													padding: '3px 3px 3px 15px',
													borderRight: '1px solid #000',
												}}
											>
												Nosso Número
											</Grid>
											<Grid
												style={{
													width: '154.5px',
													padding: '3px 3px 3px 15px',
													borderRight: '1px solid #000',
												}}
											>
												Nº do Documento
											</Grid>
											<Grid
												style={{
													width: '150px',
													padding: '3px 3px 3px 15px',
													borderRight: '1px solid #000',
												}}
											>
												Data de Vencimento{' '}
											</Grid>
											<Grid
												style={{
													width: '148.5px',
													padding: '3px 3px 3px 15px',
													borderRight: '1px solid #000',
												}}
											>
												Valor do Documento{' '}
											</Grid>
											<Grid style={{ width: '153px', padding: '3px 3px 3px 15px' }}>
												(=) Valor Cobrado{' '}
											</Grid>
										</Grid>
										<Grid
											style={{
												height: '22.5px',
												width: '829px',
												borderBottom: '1px solid #000',
												fontSize: '12px',
												fontWeight: 'bold',
												display: 'flex',
											}}
										>
											<Grid
												style={{
													width: '219px',
													padding: '3px 3px 3px 15px',
													borderRight: '1px solid #000',
												}}
											>
												{nossoNumero}
											</Grid>
											<Grid
												style={{
													width: '154.5px',
													padding: '3px 3px 3px 15px',
													borderRight: '1px solid #000',
												}}
											>
												{nrDocument}
											</Grid>
											<Grid
												style={{
													width: '150px',
													padding: '3px 3px 3px 15px',
													borderRight: '1px solid #000',
												}}
											>
												{moment(data.tipoPagamento.boleto.data_vencimento).format('DD/MM/YYYY')}
											</Grid>
											<Grid
												style={{
													width: '148.5px',
													padding: '3px 3px 3px 15px',
													borderRight: '1px solid #000',
												}}
											>
												{data.valor.replace('.', ',')}
											</Grid>
											<Grid style={{ width: '153px', padding: '3px 3px 3px 15px' }}>
												{' '}
												{data.valor.replace('.', ',')}
											</Grid>
										</Grid>

										<Grid
											style={{
												height: '16.5px',
												width: '829px',
												padding: '4px 0 0 16px',
												fontSize: '7.5px',
											}}
										>
											Nome do Beneficiário
										</Grid>
										<Grid
											style={{
												height: '22.5px',
												width: '829px',
												fontWeight: 'bold',
												fontSize: '12px',
												padding: '4px 0 0 16px',
											}}
										>
											{data.estabelecimento.nome.length > 29
												? data.estabelecimento.nome.toUpperCase().slice(0, 30)
												: data.estabelecimento.nome.toUpperCase()}{' '}
											via Zoop Brasil - 19.468.242/0001-32
										</Grid>
										<Grid
											style={{
												height: '22.5px',
												width: '812px',
												padding: '4px 0 0 16px',
												borderBottom: '1px solid #000',
											}}
										></Grid>
										<Grid
											style={{ display: 'flex', justifyContent: 'space-between', width: '829px' }}
										>
											<Grid
												style={{ maxWidth: '415px', display: 'flex', flexDirection: 'column' }}
											>
												<Grid
													style={{
														height: '15px',
														fontSize: '7.5px',
														width: '50vh',
														padding: '4px 0 0 16px',
													}}
												>
													Agência / Código do Beneficiário
												</Grid>
												<Grid
													style={{
														height: '24px',
														width: '50vh',
														fontSize: '12px',
														fontWeight: 'bold',
														padding: '0 0 0 15px',
													}}
												>
													{' '}
													0000/00000
												</Grid>
											</Grid>
											<Grid
												style={{ maxWidth: '415px', display: 'flex', flexDirection: 'column' }}
											>
												<Grid
													style={{
														height: '15px',
														width: '50vh',
														fontSize: '7.5px',
														padding: '4px 0 0 16px',
													}}
												>
													Autenticação Mecância
												</Grid>
												<Grid
													style={{
														height: '24px',
														fontSize: '12px',
														width: '50vh',
														padding: '0 0 0 15px',
													}}
												>
													{' '}
												</Grid>
											</Grid>
										</Grid>
										<Grid style={{ height: '1.5px', width: '829px' }}></Grid>
									</Grid>

									<Grid
										style={{ width: '829px', margin: '16px', borderTop: '1px dashed #999' }}
									></Grid>

									<Grid className={classes.boleto}>
										<Grid className={classes.row1}>
											<Grid className={classes.row1Coluna1}>
												<img alt="" height={25} src={flag} />
											</Grid>
											<Grid className={classes.row1Coluna2}>{code}</Grid>
											<Grid className={classes.row1Coluna3}>
												{Mask.Boleto(data.tipoPagamento.boleto.codigo_barras)}
											</Grid>
										</Grid>
										<Grid style={{ display: 'flex', flexDirection: 'row' }}>
											<Grid
												style={{
													width: '583px',
													height: '39px',
													borderBottom: '1px solid #000',
													borderRight: '1px solid #000',
												}}
											>
												<Grid style={{ fontSize: '7.5px' }}>
													<div style={{ padding: '4px 0 0 16px' }}>Local de Pagamento</div>
												</Grid>
												<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
													<div style={{ padding: '4px 0 0 16px' }}>
														ATÉ O VENCIMENTO PAGUE EM QUALQUER BANCO OU CORRESPONDENTE NÃO
														BANCÁRIO.
													</div>
												</Grid>
											</Grid>
											<Grid
												style={{
													width: '245px',
													height: '39px',
													borderBottom: '1px solid #000',
													fontSize: '12px',
												}}
											>
												<Grid style={{ fontSize: '7.5px' }}>
													<div style={{ padding: '4px 0 0 16px' }}>Data de Vencimento</div>
												</Grid>
												<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
													<div style={{ padding: '4px 0 0 16px' }}>
														{moment(data.tipoPagamento.boleto.data_vencimento).format(
															'DD/MM/YYYY'
														)}
													</div>
												</Grid>
											</Grid>
										</Grid>

										<Grid style={{ display: 'flex', flexDirection: 'row' }}>
											<Grid
												style={{
													width: '583px',
													height: '39px',
													borderBottom: '1px solid #000',
													borderRight: '1px solid #000',
												}}
											>
												<Grid style={{ fontSize: '7.5px' }}>
													<div style={{ padding: '4px 0 0 16px' }}>Beneficiário/CPF/CNPJ</div>
												</Grid>
												<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
													<div style={{ padding: '4px 0 0 16px' }}>
														{data.estabelecimento.nome.length > 29
															? data.estabelecimento.nome.toUpperCase().slice(0, 30)
															: data.estabelecimento.nome.toUpperCase()}{' '}
														via Zoop Brasil - 19.468.242/0001-32
													</div>
												</Grid>
											</Grid>
											<Grid
												style={{
													width: '245px',
													height: '39px',
													borderBottom: '1px solid #000',
													fontSize: '12px',
												}}
											>
												<Grid style={{ fontSize: '7.5px' }}>
													<div style={{ padding: '4px 0 0 16px' }}>
														Agência / Código do Beneficiário
													</div>
												</Grid>
												<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
													<div style={{ padding: '4px 0 0 16px' }}>0000/00000</div>
												</Grid>
											</Grid>
										</Grid>

										<Grid style={{ display: 'flex', flexDirection: 'row' }}>
											<Grid
												style={{
													width: '583px',
													height: '39px',
													borderBottom: '1px solid #000',
													borderRight: '1px solid #000',
													display: 'flex',
												}}
											>
												<Grid
													style={{
														width: '127.5px',
														height: '39px',
														borderRight: '1px solid #000',
													}}
												>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>Data do Documento</div>
													</Grid>
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														<div style={{ padding: '4px 0 0 16px' }}>
															{moment(dataZoop.boletoZoop.created_at).format(
																'DD/MM/YYYY'
															)}
														</div>
													</Grid>
												</Grid>
												<Grid
													style={{
														width: '134.9px',
														height: '39px',
														borderRight: '1px solid #000',
													}}
												>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>Nr. do Documento</div>
													</Grid>
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														<div style={{ padding: '4px 0 0 16px' }}>{nrDocument}</div>
													</Grid>
												</Grid>
												<Grid
													style={{
														width: '89.9px',
														height: '39px',
														borderRight: '1px solid #000',
													}}
												>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>Espécie Doc.</div>
													</Grid>
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														<div style={{ padding: '4px 0 0 16px' }}>DM</div>
													</Grid>
												</Grid>
												<Grid
													style={{
														width: '91.4px',
														height: '39px',
														borderRight: '1px solid #000',
													}}
												>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>Aceite</div>
													</Grid>
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														<div style={{ padding: '4px 0 0 16px' }}>N</div>
													</Grid>
												</Grid>
												<Grid style={{ width: '133.4px', height: '39px' }}>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>
															Data de Processamento{' '}
														</div>
													</Grid>
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														<div style={{ padding: '4px 0 0 16px' }}>
															{moment(
																dataZoop.pedidoZoop.payment_method.created_at
															).format('DD/MM/YYYY')}
														</div>
													</Grid>
												</Grid>
											</Grid>
											<Grid
												style={{
													width: '245px',
													height: '39px',
													borderBottom: '1px solid #000',
												}}
											>
												<Grid style={{ fontSize: '7.5px' }}>
													<div style={{ padding: '4px 0 0 16px' }}>Nosso Número </div>
												</Grid>
												<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
													<div style={{ padding: '4px 0 0 16px' }}>{nossoNumero}</div>
												</Grid>
											</Grid>
										</Grid>

										<Grid style={{ display: 'flex', flexDirection: 'row' }}>
											<Grid
												style={{
													width: '583px',
													height: '39px',
													borderBottom: '1px solid #000',
													borderRight: '1px solid #000',
													display: 'flex',
												}}
											>
												<Grid
													style={{
														width: '127.5px',
														height: '39px',
														borderRight: '1px solid #000',
													}}
												>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>Uso do Banco</div>
													</Grid>
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														<div style={{ padding: '4px 0 0 16px' }}></div>
													</Grid>
												</Grid>
												<Grid
													style={{
														width: '134.9px',
														height: '39px',
														borderRight: '1px solid #000',
													}}
												>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>Carteira </div>
													</Grid>
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														<div style={{ padding: '4px 0 0 16px' }}>
															{dataZoop.pedidoZoop.payment_method.bank_code
																? dataZoop.pedidoZoop.payment_method.bank_code
																: '-'}
														</div>
													</Grid>
												</Grid>
												<Grid
													style={{
														width: '89.9px',
														height: '39px',
														borderRight: '1px solid #000',
													}}
												>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>Espécie Moeda</div>
													</Grid>
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														<div style={{ padding: '4px 0 0 16px' }}>R$</div>
													</Grid>
												</Grid>
												<Grid
													style={{
														width: '91.4px',
														height: '39px',
														borderRight: '1px solid #000',
													}}
												>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>Quantidade Moeda</div>
													</Grid>
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														<div style={{ padding: '4px 0 0 16px' }}></div>
													</Grid>
												</Grid>
												<Grid style={{ width: '133.4px', height: '39px' }}>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>Valor Moeda</div>
													</Grid>
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														<div style={{ padding: '4px 0 0 16px' }}></div>
													</Grid>
												</Grid>
											</Grid>
											<Grid
												style={{
													width: '245px',
													height: '39px',
													borderBottom: '1px solid #000',
												}}
											>
												<Grid style={{ fontSize: '7.5px' }}>
													<div style={{ padding: '4px 0 0 16px' }}>
														{'(=) Valor do Documento'}
													</div>
												</Grid>
												<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
													<div style={{ padding: '4px 0 0 16px' }}>
														{data.valor.replace('.', ',')}
													</div>
												</Grid>
											</Grid>
										</Grid>

										<Grid style={{ display: 'flex', flexDirection: 'row' }}>
											<Grid className={classes.infosBeneficiario}>
												<Grid style={{ fontSize: '7.5px' }}>
													<div style={{ padding: '4px 0 0 16px' }}>
														Informações de Responsabilidade do Beneficiário
													</div>
												</Grid>
												{Isset(() => data.multa) && (
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														{data.multa.valor !== '0' && (
															<div style={{ padding: '4px 0 0 16px' }}>
																{`Multa de ${
																	data.multa.tipo_valor === 'money'
																		? Mask.Real(data.multa.valor)
																		: `${data.multa.valor}%`
																} após o ${moment(
																	data.tipoPagamento.boleto.data_vencimento
																).format('DD/MM/YYYY')}`}
															</div>
														)}
													</Grid>
												)}
												{Isset(() => data.mora) && (
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														{data.mora.valor !== '0' && (
															<div style={{ padding: '4px 0 0 16px' }}>
																{`Após ${moment(
																	data.tipoPagamento.boleto.data_vencimento
																).format('DD/MM/YYYY')}, cobrar Juros de ${
																	data.mora.tipo_valor === 'money'
																		? Mask.Real(data.mora.valor)
																		: Mask.Porcentagem(data.mora.valor)
																} ao dia.`}
															</div>
														)}
													</Grid>
												)}
												{data.desconto &&
													data.desconto.map(d => {
														return (
															<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
																<div style={{ padding: '4px 0 0 16px' }}>
																	{`Desconto de ${
																		d.mode === 'FIXED'
																			? Mask.Real(d.value / 100)
																			: `${d.value}%`
																	} para pagamento até ${moment(d.limit_date)
																		.add(3, 'hours')
																		.format('DD/MM/YYYY')}`}
																</div>
															</Grid>
														);
													})}
												<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
													<div style={{ padding: '4px 0 0 16px' }}>
														{Isset(() => data.tipoPagamento.boleto.descricao)
															? data.tipoPagamento.boleto.descricao
															: ' '}
													</div>
												</Grid>
											</Grid>
											<Grid style={{ display: 'flex', flexDirection: 'column' }}>
												<Grid
													style={{
														width: '245px',
														height: '39px',
														borderBottom: '1px solid #000',
													}}
												>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>
															{'(-) Desconto / Abatimento'}
														</div>
													</Grid>
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
														<div style={{ padding: '4px 0 0 16px' }}></div>
													</Grid>
												</Grid>
												<Grid
													style={{
														width: '245px',
														height: '39px',
														borderBottom: '1px solid #000',
													}}
												>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>
															{'(+) Mora / Multa'}
														</div>
													</Grid>
													<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}></Grid>
												</Grid>
												<Grid
													style={{
														width: '245px',
														height: '39px',
														// borderBottom: '1px solid #000',
													}}
												>
													<Grid style={{ fontSize: '7.5px' }}>
														<div style={{ padding: '4px 0 0 16px' }}>
															{'(=) Valor Cobrado'}
														</div>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										<Grid style={{ width: '829px', height: '84px', borderTop: '1px solid #000' }}>
											<Grid style={{ fontSize: '7.5px' }}>
												<div style={{ padding: '4px 0 0 16px' }}>
													Pagador/CPF/CNPJ/Endereço{' '}
												</div>
											</Grid>
											<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
												<div style={{ padding: '4px 0 0 16px' }}>
													{dataClient.cliente.nome.length > 29
														? dataClient.cliente.nome.slice(0, 30)
														: dataClient.cliente.nome}{' '}
													CPF/CNPJ: {dataClient.cliente.clientes_documentos[0].documento}
												</div>
											</Grid>
											<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
												<div style={{ padding: '4px 0 0 16px' }}>
													{dataClient.cliente.endereco.logradouro}{' '}
													{dataClient.cliente.endereco.numero}{' '}
													{dataClient.cliente.endereco.complemento &&
														dataClient.cliente.endereco.complemento}
												</div>
											</Grid>
											<Grid style={{ fontSize: '12px', fontWeight: 'bold' }}>
												<div style={{ padding: '4px 0 0 16px' }}>
													CEP: {dataClient.cliente.endereco.cep}{' '}
													{dataClient.cliente.endereco.cidade}
												</div>
											</Grid>
										</Grid>
										<Grid
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												width: '829px',
												height: '39px',
												borderTop: '1px solid #000',
											}}
										>
											<Grid
												style={{ maxWidth: '415px', display: 'flex', flexDirection: 'column' }}
											>
												<Grid
													style={{
														height: '15px',
														fontSize: '7.5px',
														width: '50vh',
														padding: '4px 0 0 16px',
													}}
												>
													Sacador/Avalista
												</Grid>
												<Grid
													style={{
														height: '24px',
														width: '50vh',
														fontSize: '12px',
														fontWeight: 'bold',
														padding: '4px 0 0 16px',
													}}
												>
													{data.estabelecimento.nome.length > 29
														? data.estabelecimento.nome.toUpperCase().slice(0, 30)
														: data.estabelecimento.nome.toUpperCase()}{' '}
													CPF/CNPJ:
													{Isset(() => data.estabelecimento.cnpj) &&
														Formatter.Cnpj(data.estabelecimento.cnpj)}
													{Isset(() => data.estabelecimento.cpf) &&
														Formatter.Cpf(data.estabelecimento.cpf)}
												</Grid>
											</Grid>
											<Grid
												style={{ maxWidth: '415px', display: 'flex', flexDirection: 'column' }}
											>
												<Grid
													style={{
														height: '15px',
														width: '50vh',
														fontSize: '7.5px',
														padding: '4px 0 0 16px',
													}}
												>
													Autenticação Mecância
												</Grid>
												<Grid
													style={{
														height: '24px',
														fontSize: '12px',
														width: '50vh',
														padding: '4px 0 0 16px',
													}}
												></Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid className={classes.barcode}>
										<GenerateBoleto codigoBarras={data.tipoPagamento.boleto.codigo_barras} />
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Container>
		</>
	);
}
