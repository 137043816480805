/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Switch } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ZSwitch from '../system/ZSwitch';

import axios from 'axios';
import config from 'react-global-configuration';
import moment from 'moment';
import { addMessage } from '../../store/alert';

import { useSelector, useDispatch } from 'react-redux';
import { Isset } from '../../utils';
import clsx from 'clsx';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import ZSaveButton from '../system/ZSaveButton';
import ZText from '../system/ZText';
import ZSelect from '../system/ZSelect';
// import ZInputFile from '../system/ZInputFile';
import { Mask, ValidarCpf, ValidatorForm } from '../../utils/';

import { registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginGetFile from 'filepond-plugin-get-file';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.css';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFilePoster);
registerPlugin(FilePondPluginGetFile);

const useStyles = makeStyles(theme => ({
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	novaContaBancariaFields: {
		padding: 10,
	},
	box: {
		position: 'relative',
		padding: 10,
		borderTop: `solid 4px transparent`,
	},
	boxAtivo: {
		borderTop: `solid 4px ${theme.palette.primary.main}`,
	},
	ativo: {
		position: 'absolute',
		right: 10,
		top: 5,
		textTransform: 'uppercase',
		fontSize: 11,
		fontWeight: 'bold',
	},
	formControl: {
		width: '100%',
		marginTop: 4,
	},
	salvarBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	textField: {
		width: '100%',
		marginTop: 4,
	},
	tipoContaBancaria: {
		padding: 6,
		fontSize: 16,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 700,
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		[theme.breakpoints.down('md')]: {
			padding: 10,
		},
	},
	icon: {
		color: theme.palette.primary.main,
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
	field: {
		padding: 5,
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	titulo: {
		display: 'flex',
		justifyContent: 'center',
		margin: '16px',
	},
	botaoInicial: {
		textAlign: 'center',
		backgroundColor: 'rgb(79, 79, 79)',
		padding: '22px',
		color: 'white',
	},
	botao: {
		textAlign: 'center',
		backgroundColor: 'rgb(79, 79, 79)',
		color: 'white',
	},
	base: {
		height: '100vh',
		background: '#D5D5D5',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	segundoStep: {
		border: '64px solid white',
		background: 'white',
		margin: 'auto',
		marginBottom: '32px',
		marginTop: '32px',
	},
}));

const validateFields = {
	tipoEstabelecimentoId: {
		required: true,
	},
	nome: {
		required: true,
	},
	email: {
		required: true,
	},
	celular: {
		required: true,
	},
	dataNascimento: {
		required: true,
	},
	dataNascimento2: '',
	cpf: {
		required: true,
	},
	categoria: {
		required: true,
	},
	faturamento_estimado: {
		required: true,
	},
	endereco: {
		logradouro: {
			required: true,
		},
		numero: {
			required: true,
		},
		cidade: {
			required: true,
		},
		estado: {
			required: true,
		},
		cep: {
			required: true,
		},
	},
};

const ValidateFieldsPessoaJuridica = {
	tipoEstabelecimentoId: {
		required: true,
	},
	razaoSocial: {
		required: true,
	},
	nomeFantasia: {
		required: true,
	},
	telefone: {
		required: true,
	},
	email: {
		required: true,
	},
	cnpj: {
		required: true,
	},
	categoria: {
		required: true,
	},
	faturamento_estimado: {
		required: true,
	},
	endereco: {
		logradouro: {
			required: true,
		},
		numero: {
			required: true,
		},
		cidade: {
			required: true,
		},
		estado: {
			required: true,
		},
		cep: {
			required: true,
		},
	},
};

export default props => {
	const classes = useStyles();

	// const { history, match } = useReactRouter();
	const history = useHistory();
	const match = useRouteMatch();

	const dispatch = useDispatch();

	const token = useSelector(store => store.auth.token);
	// const usuario = useSelector(store => store.auth.usuario);
	const estabelecimento = useSelector(store => store.auth.estabelecimento);
	const usuario = useSelector(store => store.auth.usuario);
	const adminEstabelecimento = useSelector(state => state.admin.estabelecimento);

	const isEdmond = usuario.marketplace.id === 17;
	const isModerador = [1, 10].includes(usuario.grupo_id);

	// const [title, setTitle] = useState('Novo Estabelecimento');
	const [loading, setLoading] = useState(false);
	const [onError, setOnError] = useState(false);

	const [logoData, setLogoData] = useState([]);
	const [documentos, setDocumentos] = useState([]);
	const [documentosProprietario, setDocumentosProprietario] = useState([]);
	const [documentosAtividade, setDocumentosAtividade] = useState([]);
	const [documentosResidencia, setDocumentosResidencia] = useState([]);
	const [outrosDocumentos, setOutrosDocumentos] = useState([]);
	// const [novoArquivo, setNovoArquivo] = useState(false);

	const [step, setStep] = useState(1);

	const [bancos, setBancos] = useState([]);
	const [errors, setErrors] = useState({});

	const [categorias, setCategorias] = useState([]);
	const [switchBoolean, setSwitch] = useState(false);

	const canInsertLogo = useSelector(store =>
		Isset(() => store.system.params['18'].valor) ? store.system.params['18'].valor : null
	);

	const [data, setData] = useState({
		tipoEstabelecimentoId: 1,
		identificadorPlano: '',
		nome: '',
		nomeComprovante: '',
		email: '',
		telefone: '',
		celular: '',
		dataNascimento: '',
		dataNascimento2: '',
		cpf: '',
		categoria: '',
		descricao_categoria: '',
		quantidade_pos: 0,
		faturamento_estimado: 0,
		observacao: '',
		endereco: {
			logradouro: '',
			numero: '',
			cidade: '',
			estado: '',
			cep: '',
			bairro: '',
			complemento: '',
		},
		enderecoPOS: {
			logradouro: '',
			numero: '',
			cidade: '',
			estado: '',
			cep: '',
			bairro: '',
			complemento: '',
		},
		proprietario: {
			nome: '',
			sobrenome: '',
			email: '',
			celular: '',
			dataNascimento: '',
			cpf: '',
			endereco: {
				logradouro: '',
				numero: '',
				cidade: '',
				estado: '',
				cep: '',
				complemento: '',
				bairro: '',
			},
		},
		contaBancaria: {
			tipoContaBancaria: 1,
			nomeTitular: '',
			bancoId: isEdmond && !isModerador ? '112' : '',
			agencia: '',
			conta: '',
		},
		desativarVendas: false,
	});

	useEffect(() => {
		const getCategorias = async () => {
			const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/mcc`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success && Isset(() => res.data.result)) {
				const cat = [];
				Object.keys(res.data.result).forEach(a => {
					const cats = res.data.result[a];
					cat.push({ id: null, description: a });
					cats.forEach(c => cat.push({ id: c.id, description: c.description }));
				});

				setCategorias(cat);
			} else {
				history.push('/error');
			}
		};

		const getBancos = async () => {
			const result = await axios.get(`${config.get('apiUrl')}bancos`);

			if (result.data.success) {
				setBancos(result.data.bancos);
			}
		};

		getBancos();

		getCategorias();
	}, []);

	useEffect(() => {
		if (match.params.estabelecimentoId) {
			// setTitle('Editar Estabelecimento');

			axios
				.get(`${config.get('apiUrl')}estabelecimentos/${match.params.estabelecimentoId}`, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(res => res.data)
				.then(dados => {
					if (dados.success) {
						const ec = dados.estabelecimento;
						const telefone = ec.estabelecimentos_contatos.find(a => a.tipo_contato_id === 1);
						const celular = ec.estabelecimentos_contatos.find(a => a.tipo_contato_id === 2);
						const email = ec.estabelecimentos_contatos.find(a => a.tipo_contato_id === 3);

						const cpf = ec.estabelecimentos_documentos.find(a => a.tipo_documento_id === 2);
						const cnpj = ec.estabelecimentos_documentos.find(a => a.tipo_documento_id === 3);

						const { endereco, endereco_pos } = ec;
						const { endereco: enderecoProprietario } = ec.estabelecimento_proprietario || {};
						setSwitch(ec.pos_endereco_id ? true : false);
						setData({
							...data,
							tipoEstabelecimentoId: ec.tipo_estabelecimento.id,
							nome: ec.nome_fantasia,
							nomeComprovante: ec.identificacao_fatura,
							razaoSocial: ec.razao_social,
							identificadorPlano: ec.identificador_plano,
							nomeFantasia: ec.nome_fantasia,
							email: Isset(() => email.contato) ? email.contato : '',
							telefone: Isset(() => telefone.contato) ? telefone.contato : '',
							celular: Isset(() => celular.contato) ? celular.contato : '',
							dataNascimento: ec.data_nascimento,
							dataNascimento2: moment(ec.data_nascimento).format('DD/MM/YYYY'),
							cpf: Isset(() => cpf.documento) ? cpf.documento : '',
							cnpj: Isset(() => cnpj.documento) ? cnpj.documento : '',
							quantidade_pos: ec.quantidade_pos || 0,
							observacao: ec.observacao || '',
							faturamento_estimado: ec.faturamento_estimado / 100,
							categoria: ec.mcc || '',
							endereco: {
								logradouro: endereco.logradouro,
								numero: endereco.numero,
								cidade: endereco.cidade,
								estado: endereco.uf,
								cep: endereco.cep,
								complemento: endereco.complemento,
								bairro: endereco.bairro || '',
							},
							enderecoPOS: {
								logradouro: endereco_pos !== null ? endereco_pos.logradouro : '',
								numero: endereco_pos !== null ? endereco_pos.numero : '',
								cidade: endereco_pos !== null ? endereco_pos.cidade : '',
								estado: endereco_pos !== null ? endereco_pos.uf : '',
								cep: endereco_pos !== null ? endereco_pos.cep : '',
								complemento: endereco_pos !== null ? endereco_pos.complemento || '' : '',
								bairro: endereco_pos !== null ? endereco_pos.bairro || '' : '',
							},
							proprietario: {
								...data.proprietario,
								...ec.estabelecimento_proprietario,
								dataNascimento: Isset(() => ec.estabelecimento_proprietario.data_nascimento)
									? moment(ec.estabelecimento_proprietario.data_nascimento).format('DD/MM/YYYY')
									: '',
								endereco: {
									...data.proprietario.endereco,
									...enderecoProprietario,
									estado: Isset(() => enderecoProprietario.uf) ? enderecoProprietario.uf : '',
									bairro: Isset(() => enderecoProprietario.bairro) ? enderecoProprietario.bairro : '',
								},
							},
							desativarVendas: ec.desativarVendas,
						});

						if (Isset(() => ec.arquivo.url)) {
							setLogoData(ec.arquivo.url);
						}

						const docs = ec.estabelecimentos_documentos
							.filter(a => a.tipo_documento_id === 5 && Isset(() => a.arquivo.url))
							.map(a => ({
								source: a.arquivo.url,
								options: {
									type: 'local',
									file: {
										id: a.arquivo.id,
										name: a.arquivo.nome,
										size: a.arquivo.tamanho,
										type: a.arquivo.mimetype,
									},
									metadata: {
										poster: a.arquivo.url,
									},
								},
							}));
						const docsAtividade = ec.estabelecimentos_documentos
							.filter(a => a.tipo_documento_id === 6 && Isset(() => a.arquivo.url))
							.map(a => ({
								source: a.arquivo.url,
								options: {
									type: 'local',
									file: {
										id: a.arquivo.id,
										name: a.arquivo.nome,
										size: a.arquivo.tamanho,
										type: a.arquivo.mimetype,
									},
									metadata: {
										poster: a.arquivo.url,
									},
								},
							}));
						const docsResidencia = ec.estabelecimentos_documentos
							.filter(a => a.tipo_documento_id === 7 && Isset(() => a.arquivo.url))
							.map(a => ({
								source: a.arquivo.url,
								options: {
									type: 'local',
									file: {
										id: a.arquivo.id,
										name: a.arquivo.nome,
										size: a.arquivo.tamanho,
										type: a.arquivo.mimetype,
									},
									metadata: {
										poster: a.arquivo.url,
									},
								},
							}));
						const docsProprietario = ec.estabelecimentos_documentos
							.filter(a => a.tipo_documento_id === 8 && Isset(() => a.arquivo.url))
							.map(a => ({
								source: a.arquivo.url,
								options: {
									type: 'local',
									file: {
										id: a.arquivo.id,
										name: a.arquivo.nome,
										size: a.arquivo.tamanho,
										type: a.arquivo.mimetype,
									},
									metadata: {
										poster: a.arquivo.url,
									},
								},
							}));
						const outrosDocs = ec.estabelecimentos_documentos
							.filter(a => a.tipo_documento_id === 4 && Isset(() => a.arquivo.url))
							.map(a => ({
								source: a.arquivo.url,
								options: {
									type: 'local',
									file: {
										id: a.arquivo.id,
										name: a.arquivo.nome,
										size: a.arquivo.tamanho,
										type: a.arquivo.mimetype,
									},
									metadata: {
										poster: a.arquivo.url,
									},
								},
							}));

						setDocumentos(docs);
						setDocumentosAtividade(docsAtividade);
						setDocumentosResidencia(docsResidencia);
						setDocumentosProprietario(docsProprietario);
						setOutrosDocumentos(outrosDocs);
					} else {
						setErrors({
							estabelecimento: true,
						});
					}
				});
		}
	}, [match.params.estabelecimentoId, token]);

	// const validateContaBancaria = () => {
	// 	const e = {};""

	// 	if (!data.contaBancaria.nomeTitular) {
	// 		e.nomeTitular = true;
	// 	}
	// 	if (!data.contaBancaria.bancoId) {
	// 		e.bancoId = true;
	// 	}
	// 	if (!data.contaBancaria.agencia) {
	// 		e.agencia = true;
	// 	}
	// 	if (!data.contaBancaria.conta) {
	// 		e.conta = true;
	// 	}

	// 	setErrors(e);

	// 	return Object.keys(e).length > 0;
	// };

	const handleSubmitForm = async () => {
		try {
			// setErrors([]);
			setLoading(true);
			try {
				if (+data.tipoEstabelecimentoId === 1) {
					ValidatorForm(validateFields, data);
				} else {
					ValidatorForm(ValidateFieldsPessoaJuridica, data);
				}
			} catch (erro) {
				window.scrollTo(0, 0);
				dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));
				setLoading(false);
				setOnError(true);
				return false;
			}

			data.estabelecimentoId = Isset(() => adminEstabelecimento.id)
				? adminEstabelecimento.id
				: estabelecimento.id;

			data.desativarVendas = data.desativarVendas ? 1 : 0;

			var formData = new FormData();

			for (let key in data) {
				if (typeof data[key] === 'object') {
					for (let key2 in data[key]) {
						if (typeof data[key][key2] === 'object') {
							for (let key3 in data[key][key2]) {
								formData.append(`${key}[${key2}][${key3}]`, data[key][key2][key3]);
							}
						} else {
							formData.append(`${key}[${key2}]`, data[key][key2]);
						}
					}
				} else {
					formData.append(key, data[key]);
				}
			}

			// const logo = !Array.isArray(logoData) ? [logoData] : logoData;
			const documentosFiles = !Array.isArray(documentos) ? [documentos] : documentos;
			const documentosProprietarioFiles = !Array.isArray(documentosProprietario)
				? [documentosProprietario]
				: documentosProprietario;
			const documentosAtividadeFiles = !Array.isArray(documentosAtividade)
				? [documentosAtividade]
				: documentosAtividade;
			const documentosResidenciaFiles = !Array.isArray(documentosResidencia)
				? [documentosResidencia]
				: documentosResidencia;
			const outrosDocumentosFiles = !Array.isArray(outrosDocumentos) ? [outrosDocumentos] : outrosDocumentos;

			// if (canInsertLogo) {
			// 	logo.forEach(a => {
			// 		if (!a.type.match('image.*')) {
			// 			return;
			// 		}
			// 		formData.append('logo', a, a.name);
			// 	});
			// }

			const addFile = (name, file, fd) => {
				if (!(file instanceof Blob)) {
					fd.append(`documentosExistentes[]`, file.id);
					return;
				}
				if (!file.type.match('image.*') && !file.type.match('application/pdf')) {
					return;
				}
				fd.append(`${name}[]`, file, file.name);
			};

			documentosFiles.forEach(a => addFile('documentos', a, formData));
			documentosProprietarioFiles.forEach(a => addFile('documentosProprietario', a, formData));
			documentosAtividadeFiles.forEach(a => addFile('documentosAtividade', a, formData));
			documentosResidenciaFiles.forEach(a => addFile('documentosResidencia', a, formData));
			outrosDocumentosFiles.forEach(files => addFile('outrosDocumentos', files, formData));

			if (!Isset(() => match.params.estabelecimentoId) || match.params.estabelecimentoId === null) {
				// if (validateContaBancaria()) {
				// 	setLoading(false);
				// 	return false;
				// }
				const res = await axios.post(`${config.get('apiUrl')}estabelecimentos`, formData, {
					headers: { Authorization: `Bearer ${token}` },
				});
				setLoading(false);
				if (res.data.success === false) {
					dispatch(addMessage({ type: 'error', message: res.data.error }));
				} else {
					dispatch(addMessage({ type: 'success', message: res.data.message }));
					history.push('/estabelecimentos');
				}
			} else {
				const res = await axios.put(
					`${config.get('apiUrl')}estabelecimentos/${match.params.estabelecimentoId}`,
					formData,
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);

				setLoading(false);
				if (res.data.success === false) {
					dispatch(addMessage({ type: 'error', message: res.data.error }));
				} else {
					dispatch(addMessage({ type: 'success', message: res.data.message }));
					history.push('/estabelecimentos');
				}
			}
		} catch (error) {
			setLoading(false);
			dispatch(addMessage({ type: 'error', message: error.response.data.error }));
			await axios.post(
				`${config.get('apiUrl')}sendReport`,
				{
					title: 'Error - Cadastrar Estabelecimento',
					error: JSON.stringify(error),
				},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);
		}
	};

	const handleSubmitFormPreCadastro = async (status = 0) => {
		try {
			// setErrors([]);
			setLoading(true);
			try {
				if (+data.tipoEstabelecimentoId === 1) {
					ValidatorForm(validateFields, data);
				} else {
					ValidatorForm(ValidateFieldsPessoaJuridica, data);
				}
			} catch (erro) {
				window.scrollTo(0, 0);
				dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));
				setLoading(false);
				setOnError(true);
				return false;
			}

			data.estabelecimentoId = Isset(() => adminEstabelecimento.id)
				? adminEstabelecimento.id
				: estabelecimento.id;

			var formData = new FormData();

			for (let key in data) {
				if (typeof data[key] === 'object') {
					for (let key2 in data[key]) {
						if (typeof data[key][key2] === 'object') {
							for (let key3 in data[key][key2]) {
								formData.append(`${key}[${key2}][${key3}]`, data[key][key2][key3]);
							}
						} else {
							formData.append(`${key}[${key2}]`, data[key][key2]);
						}
					}
				} else {
					formData.append(key, data[key]);
				}
			}
			const logo = !Array.isArray(logoData) ? [logoData] : logoData;
			const documentosFiles = !Array.isArray(documentos) ? [documentos] : documentos;
			const documentosProprietarioFiles = !Array.isArray(documentosProprietario)
				? [documentosProprietario]
				: documentosProprietario;
			const documentosAtividadeFiles = !Array.isArray(documentosAtividade)
				? [documentosAtividade]
				: documentosAtividade;
			const documentosResidenciaFiles = !Array.isArray(documentosResidencia)
				? [documentosResidencia]
				: documentosResidencia;
			const outrosDocumentosFiles = !Array.isArray(outrosDocumentos) ? [outrosDocumentos] : outrosDocumentos;

			if (canInsertLogo) {
				logo.forEach(a => {
					if (!a.type.match('image.*')) {
						return;
					}
					formData.append('logo', a, a.name);
				});
			}

			const addFile = (name, file, fd) => {
				if (!(file instanceof Blob)) {
					fd.append(`documentosExistentes[]`, file.id);
					return;
				}
				if (!file.type.match('image.*') && !file.type.match('application/pdf')) {
					return;
				}
				fd.append(`${name}[]`, file, file.name);
			};

			documentosFiles.forEach(a => addFile('documentos', a, formData));
			documentosProprietarioFiles.forEach(a => addFile('documentosProprietario', a, formData));
			documentosAtividadeFiles.forEach(a => addFile('documentosAtividade', a, formData));
			documentosResidenciaFiles.forEach(a => addFile('documentosResidencia', a, formData));
			outrosDocumentosFiles.forEach(a => addFile('outrosDocumentos', a, formData));

			if (!Isset(() => match.params.estabelecimentoId) || match.params.estabelecimentoId === null) {
				// if (validateContaBancaria()) {
				// 	setLoading(false);
				// 	return false;
				// }
				formData.delete('desativarVendas');
				const res = await axios.post(`${config.get('apiUrl')}estabelecimentos/pre-cadastrar`, formData, {
					headers: { Authorization: `Bearer ${token}` },
				});

				setLoading(false);
				if (!res.data.success) {
					dispatch(addMessage({ type: 'error', message: res.data.error }));
				} else {
					dispatch(addMessage({ type: 'success', message: res.data.message }));
					history.push('/estabelecimentos');
				}
			} else {
				formData.append('status', status);
				let res = false;
				if (data.status_estabelecimento_id === 5) {
					formData.append('old_id', match.params.estabelecimentoId);
					res = await axios.post(`${config.get('apiUrl')}estabelecimentos/pre-cadastrar/`, formData, {
						headers: { Authorization: `Bearer ${token}` },
					});
				} else {
					res = await axios.put(
						`${config.get('apiUrl')}estabelecimentos/alterar_status_pre_cadastro/${
							match.params.estabelecimentoId
						}`,
						formData,
						{
							headers: { Authorization: `Bearer ${token}` },
						}
					);
				}

				setLoading(false);
				if (!res.data.success) {
					dispatch(addMessage({ type: 'error', message: res.data.error }));
				} else {
					dispatch(addMessage({ type: 'success', message: res.data.message }));
					history.push('/estabelecimentos/listar-pre-cadastro');
				}
			}
		} catch (error) {
			console.log(error.message);
			setLoading(false);
			// await axios.post(
			// 	`${config.get('apiUrl')}sendReport`,
			// 	{
			// 		title: 'Error - Pré Cadastrar Estabelecimento',
			// 		error: JSON.stringify(error),
			// 	},
			// 	{
			// 		headers: { Authorization: `Bearer ${token}` },
			// 	}
			// );
		}
	};

	const handleDesativarVendas = () => {
		setData({ ...data, desativarVendas: !data.desativarVendas });
	};

	const handleTipoEstabelecimento = name => event => {
		setData({
			...data,
			tipoEstabelecimentoId: +event.target.value,
		});
	};

	const handleChange = name => event => {
		if (name === 'cpf') {
			if (!ValidarCpf(event.target.value)) {
				// setErrors(['CPF inválido']);
				dispatch(addMessage({ type: 'error', message: 'CPF inválido' }));
				setData({
					...data,
					cpf: '',
				});
				return false;
			}
		}

		var value = event.target.value;
		let onlyNumber = ['celular', 'telefone', 'cnpj', 'cpf', 'celular'];

		if (onlyNumber.indexOf(name) !== -1) value = Mask.OnlyNumber(value);

		setData({
			...data,
			[name]: value,
		});
	};

	const handleDataNascimento = name => event => {
		if (name === 'dataNascimento2') {
			setData({
				...data,
				[name]: event.target.value,
				dataNascimento: moment(event.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
			});
		}
	};

	const handleProprietario = name => event => {
		setData({
			...data,
			proprietario: {
				...data.proprietario,
				[name]: event.target.value,
			},
		});
	};

	const getCep = async cep => {
		if (cep.length === 8) {
			var res = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
			if (res.status !== 200) {
				setData({
					...data,
					endereco: {
						...data.endereco,
						cep: cep,
						logradouro: '',
						cidade: '',
						estado: '',
						complemento: '',
						bairro: '',
					},
				});
				return false;
			}

			setData({
				...data,
				endereco: {
					...data.endereco,
					cep: Mask.OnlyNumber(res.data.cep),
					logradouro: res.logradouro,
					cidade: res.localidade,
					estado: res.uf,
					complemento: res.complemento,
					bairro: res.bairro,
				},
			});
		}
	};

	const handleChangeEndereco = name => async event => {
		var value = event.target.value;
		if (name === 'cep') {
			value = Mask.OnlyNumber(value);
		}

		if (name === 'cep' && event.target.value.length === 9) {
			var res = await axios.get(`https://viacep.com.br/ws/${value}/json/`);

			if (res.status !== 200) {
				setData({
					...data,
					endereco: {
						...data.endereco,
						cep: value,
						logradouro: '',
						cidade: '',
						estado: '',
						complemento: '',
					},
				});
				// setErrors([ 'CEP não encontrado' ]);
				dispatch(addMessage({ type: 'error', message: 'CEP não encontrado' }));
				return false;
			}
			setData({
				...data,
				endereco: {
					...data.endereco,
					cep: value,
					logradouro: res.data.logradouro,
					cidade: res.data.localidade,
					estado: res.data.uf,
					complemento: '',
					bairro: res.data.bairro,
				},
			});
		} else {
			setData({
				...data,
				endereco: {
					...data.endereco,
					[name]: value,
				},
			});
		}
	};

	const handleChangeProprietarioEndereco = name => async event => {
		var value = event.target.value;
		if (name === 'cep') {
			value = Mask.OnlyNumber(value);
		}

		if (name === 'cep' && event.target.value.length === 9) {
			var res = await axios.get(`https://viacep.com.br/ws/${value}/json/`);

			if (res.status !== 200) {
				setData({
					...data,
					proprietario: {
						...data.proprietario,
						endereco: {
							...data.proprietario.endereco,
							cep: value,
							logradouro: '',
							cidade: '',
							estado: '',
							complemento: '',
						},
					},
				});
				// setErrors([ 'CEP não encontrado' ]);
				dispatch(addMessage({ type: 'error', message: 'CEP não encontrado' }));
				return false;
			}

			setData({
				...data,
				proprietario: {
					...data.proprietario,
					endereco: {
						...data.proprietario.endereco,
						cep: value,
						logradouro: res.data.logradouro,
						cidade: res.data.localidade,
						estado: res.data.uf,
						complemento: '',
						bairro: res.data.bairro,
					},
				},
			});
		} else {
			setData({
				...data,
				proprietario: {
					...data.proprietario,
					endereco: {
						...data.proprietario.endereco,
						[name]: value,
					},
				},
			});
		}
	};

	if (errors.estabelecimento) {
		return (
			<Grid>
				{/* <ZTitle title={'Estabelecimento não encontrado'} /> */}
				<Grid container>
					<Grid item xs={12} align="center">
						<h2> Estabelecimento não encontrado. </h2>
						<Link to="/estabelecimentos"> Clique aqui para voltar a listagem de estabelecimentos.</Link>
					</Grid>
				</Grid>
			</Grid>
		);
	}

	return (
		// <Grid>
		// 	<ZTitle title={title} />
		// 	<Grid container>
		// 		<Grid item xs={12}>
		// 			<Paper style={{ padding: 15 }}>
		// 				<Grid item xs={12}>
		// 					<Grid container spacing={3}>
		// 						<Grid item xs={12} style={{ marginBottom: 10 }}>
		// 							<ZSwitch
		// 								onChange={handleDesativarVendas}
		// 								label={`Desativar Vendas Desse Estabelecimento?`}
		// 								// labelPlacement="top"
		// 								checked={data.desativarVendas}
		// 							/>
		// 						</Grid>
		// 					</Grid>
		// 					<Grid container spacing={3}>
		// 						<Grid item xs={12} style={{ marginBottom: 10 }}>
		// 							<Typography variant="h6" gutterBottom>
		// 								Informações do estabelecimento
		// 							</Typography>
		// 							<Divider />
		// 						</Grid>
		// 						<Grid item xs={12} sm={6} md={3} lg={2}>
		// 							<ZSelect
		// 								options={[
		// 									{
		// 										id: 1,
		// 										name: 'Pessoa Física',
		// 									},
		// 									{
		// 										id: 2,
		// 										name: 'Pessoa Jurídica',
		// 									},
		// 								]}
		// 								label="Tipo"
		// 								required={true}
		// 								value={data.tipoEstabelecimentoId}
		// 								onChange={handleTipoEstabelecimento('tipoEstabelecimentoId')}
		// 							/>
		// 						</Grid>
		// 						{data.tipoEstabelecimentoId === 1 && (
		// 							<React.Fragment>
		// 								<Grid item xs={12} sm={6} md={3} lg={2}>
		// 									<ZText
		// 										label="Nome"
		// 										onError={onError}
		// 										required={true}
		// 										value={data.nome}
		// 										onBlur={handleChange('nome')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={3} lg={2}>
		// 									<ZText
		// 										label="E-mail"
		// 										onError={onError}
		// 										required={true}
		// 										type="email"
		// 										email={true}
		// 										value={data.email}
		// 										onBlur={handleChange('email')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={3} lg={2}>
		// 									<ZText
		// 										label="Celular"
		// 										onError={onError}
		// 										required={true}
		// 										value={data.celular}
		// 										mask="(99) 9 9999-9999"
		// 										onBlur={handleChange('celular')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={3} lg={2}>
		// 									<ZText
		// 										label="Data Nascimento"
		// 										onError={onError}
		// 										required={true}
		// 										value={data.dataNascimento2}
		// 										mask="99/99/9999"
		// 										onBlur={handleDataNascimento('dataNascimento2')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={3} lg={2}>
		// 									<ZText
		// 										label="CPF"
		// 										onError={onError}
		// 										required={true}
		// 										value={data.cpf}
		// 										mask="999.999.999-99"
		// 										onBlur={handleChange('cpf')}
		// 									/>
		// 								</Grid>
		// 							</React.Fragment>
		// 						)}

		// 						{data.tipoEstabelecimentoId === 2 && (
		// 							<React.Fragment>
		// 								<Grid item xs={12} sm={6} md={3} lg={3}>
		// 									<ZText
		// 										label="Razão social"
		// 										onError={onError}
		// 										required={true}
		// 										value={data.razaoSocial}
		// 										onBlur={handleChange('razaoSocial')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={3} lg={3}>
		// 									<ZText
		// 										label="Nome Fantasia"
		// 										onError={onError}
		// 										required={true}
		// 										value={data.nomeFantasia}
		// 										onBlur={handleChange('nomeFantasia')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={3} lg={2}>
		// 									<ZText
		// 										label="CNPJ"
		// 										onError={onError}
		// 										required={true}
		// 										mask="99.999.999/9999-99"
		// 										value={data.cnpj}
		// 										onBlur={handleChange('cnpj')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={3} lg={2}>
		// 									<ZText
		// 										label="Celular"
		// 										onError={onError}
		// 										required={true}
		// 										value={data.celular}
		// 										onBlur={handleChange('celular')}
		// 										mask="(99) 99999 9999"
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={3} lg={2}>
		// 									<ZText
		// 										label="Telefone"
		// 										onError={onError}
		// 										required={true}
		// 										value={data.telefone}
		// 										onBlur={handleChange('telefone')}
		// 										mask={'(99) 9999 9999'}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={3} lg={2}>
		// 									<ZText
		// 										label="E-mail"
		// 										onError={onError}
		// 										required={true}
		// 										value={data.email}
		// 										onBlur={handleChange('email')}
		// 									/>
		// 								</Grid>
		// 							</React.Fragment>
		// 						)}

		// 						<Grid item xs={12} sm={6} md={3} lg={2}>
		// 							<ZText
		// 								label="Nome no Comprovante"
		// 								// onError={onError}
		// 								// required={true}
		// 								value={data.nomeComprovante}
		// 								onBlur={handleChange('nomeComprovante')}
		// 							/>
		// 						</Grid>

		// 						<Grid item xs={12} sm={6} md={3} lg={2}>
		// 							<FormControl style={{ marginTop: 15, width: '100%' }}>
		// 								<InputLabel>Categoria</InputLabel>
		// 								<Select
		// 									value={categorias.length > 0 ? data.categoria : ''}
		// 									onChange={e => setData({ ...data, categoria: e.target.value })}
		// 									required={true}
		// 								>
		// 									<MenuItem value="">
		// 										<em>Categoria</em>
		// 									</MenuItem>
		// 									{categorias.map((categoria, i) => {
		// 										if (!categoria.id) {
		// 											return (
		// 												<ListSubheader key={i}>{categoria.description}</ListSubheader>
		// 											);
		// 										}

		// 										return (
		// 											<MenuItem key={i} value={categoria.id}>
		// 												{categoria.description}
		// 											</MenuItem>
		// 										);
		// 									})}
		// 								</Select>
		// 								{onError && !data.categoria && (
		// 									<div style={{ color: 'red' }}>O campo não pode ser vazio.</div>
		// 								)}
		// 							</FormControl>
		// 						</Grid>
		// 						<Grid item xs={12} sm={6} md={3} lg={2}>
		// 							<ZText
		// 								label={
		// 									match.params.estabelecimentoId ? 'Plano inicial' : 'Identificador do plano'
		// 								}
		// 								onError={onError}
		// 								disabled={match.params.estabelecimentoId ? true : false}
		// 								required={false}
		// 								value={data.identificadorPlano || ''}
		// 								onBlur={handleChange('identificadorPlano')}
		// 							/>
		// 						</Grid>
		// 						<Grid item xs={12} sm={6} md={3} lg={2}>
		// 							<ZText
		// 								label={'Quantidade de POS'}
		// 								// onError={onError}
		// 								// disabled={match.params.estabelecimentoId ? true : false}
		// 								// required={false}
		// 								value={data.quantidade_pos}
		// 								onBlur={e => {
		// 									setData({ ...data, quantidade_pos: Mask.OnlyNumber(e.target.value) });
		// 								}}
		// 							/>
		// 						</Grid>
		// 						<Grid item xs={12} sm={6} md={3} lg={2}>
		// 							<ZText
		// 								label={'Faturamento estimado'}
		// 								// onError={onError}
		// 								// disabled={match.params.estabelecimentoId ? true : false}
		// 								// required={false}
		// 								value={Mask.Real(data.faturamento_estimado || 0)}
		// 								onChange={value =>
		// 									setData({ ...data, faturamento_estimado: Mask.OnlyNumber(value) / 100 })
		// 								}
		// 							/>
		// 						</Grid>
		// 						<Grid item xs={12} sm={12} md={12} lg={12}>
		// 							<ZText
		// 								label={'Observações'}
		// 								type={'textarea'}
		// 								// onError={onError}
		// 								// disabled={match.params.estabelecimentoId ? true : false}
		// 								// required={false}
		// 								value={data.observacao}
		// 								onBlur={e => {
		// 									setData({ ...data, observacao: e.target.value });
		// 								}}
		// 							/>
		// 						</Grid>
		// 					</Grid>

		// 					<Grid container spacing={3}>
		// 						<Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
		// 							<Typography variant="h6" gutterBottom>
		// 								Endereço
		// 							</Typography>
		// 							<Divider />
		// 						</Grid>
		// 						<React.Fragment>
		// 							<Grid item xs={12} sm={6} md={2} lg={2}>
		// 								<ZText
		// 									label="CEP"
		// 									onError={onError}
		// 									required={true}
		// 									mask="99999-999"
		// 									value={data.endereco.cep}
		// 									onBlur={handleChangeEndereco('cep')}
		// 								/>
		// 							</Grid>
		// 							<Grid item xs={12} sm={6} md={1} lg={1}>
		// 								<ZText
		// 									label="Número"
		// 									onError={onError}
		// 									required={true}
		// 									value={data.endereco.numero}
		// 									onBlur={handleChangeEndereco('numero')}
		// 								/>
		// 							</Grid>
		// 							<Grid item xs={12} sm={6} md={3} lg={3}>
		// 								<ZText
		// 									label="Logradouro"
		// 									value={data.endereco.logradouro}
		// 									onBlur={handleChangeEndereco('logradouro')}
		// 								/>
		// 							</Grid>
		// 							<Grid item xs={12} sm={6} md={3} lg={3}>
		// 								<ZText
		// 									label="Bairro"
		// 									value={data.endereco.bairro}
		// 									onBlur={handleChangeEndereco('bairro')}
		// 								/>
		// 							</Grid>
		// 							<Grid item xs={12} sm={6} md={2} lg={2}>
		// 								<ZText
		// 									label="Cidade"
		// 									value={data.endereco.cidade}
		// 									onBlur={handleChangeEndereco('cidade')}
		// 								/>
		// 							</Grid>
		// 							<Grid item xs={12} sm={6} md={1} lg={1}>
		// 								<ZText
		// 									label="Estado"
		// 									value={data.endereco.estado}
		// 									onBlur={handleChangeEndereco('estado')}
		// 								/>
		// 							</Grid>
		// 							<Grid item sm={6} xs={12} md={3} lg={3}>
		// 								<ZText
		// 									label="Complemento"
		// 									onError={onError}
		// 									value={data.endereco.complemento}
		// 									onBlur={handleChangeEndereco('complemento')}
		// 								/>
		// 							</Grid>
		// 						</React.Fragment>
		// 					</Grid>

		// 					{data.tipoEstabelecimentoId === 2 && (
		// 						<Grid
		// 							style={{ margin: 0, padding: 6, border: 'solid 1px #abc', borderRadius: 10 }}
		// 							container
		// 							spacing={2}
		// 						>
		// 							<Grid item xs={12}>
		// 								<Typography variant="h6" gutterBottom>
		// 									Proprietário
		// 								</Typography>
		// 								<Divider />
		// 							</Grid>
		// 							<Grid item container xs={12} md={6} spacing={2}>
		// 								<Grid style={{ fontWeight: 'bold', fontSize: 16 }} item xs={12}>
		// 									Dados
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={4} lg={4}>
		// 									<ZText
		// 										label="Nome"
		// 										value={data.proprietario.nome}
		// 										onBlur={handleProprietario('nome')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={4} lg={4}>
		// 									<ZText
		// 										label="Sobrenome"
		// 										value={data.proprietario.sobrenome}
		// 										onBlur={handleProprietario('sobrenome')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={4} lg={4}>
		// 									<ZText
		// 										label="E-mail"
		// 										type="email"
		// 										email={true}
		// 										value={data.proprietario.email}
		// 										onBlur={handleProprietario('email')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={4} lg={4}>
		// 									<ZText
		// 										label="Celular"
		// 										value={data.proprietario.celular}
		// 										mask="(99) 9 9999-9999"
		// 										onBlur={handleProprietario('celular')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={4} lg={4}>
		// 									<ZText
		// 										label="Data Nascimento"
		// 										value={data.proprietario.dataNascimento}
		// 										mask="99/99/9999"
		// 										onBlur={handleProprietario('dataNascimento')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={4} lg={4}>
		// 									<ZText
		// 										label="CPF"
		// 										value={data.proprietario.cpf}
		// 										mask="999.999.999-99"
		// 										onBlur={handleProprietario('cpf')}
		// 									/>
		// 								</Grid>
		// 							</Grid>
		// 							<Grid item container xs={12} md={6} spacing={2}>
		// 								<Grid style={{ fontWeight: 'bold', fontSize: 16 }} item xs={12}>
		// 									Endereço
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={4} lg={4}>
		// 									<ZText
		// 										label="CEP"
		// 										// onError={onError}
		// 										// required={true}
		// 										mask="99999-999"
		// 										value={data.proprietario.endereco.cep}
		// 										onBlur={handleChangeProprietarioEndereco('cep')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={2} lg={2}>
		// 									<ZText
		// 										label="Número"
		// 										// onError={onError}
		// 										// required={true}
		// 										value={data.proprietario.endereco.numero}
		// 										onBlur={handleChangeProprietarioEndereco('numero')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={6} lg={6}>
		// 									<ZText
		// 										label="Logradouro"
		// 										value={data.proprietario.endereco.logradouro}
		// 										onBlur={handleChangeProprietarioEndereco('logradouro')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={4} lg={4}>
		// 									<ZText
		// 										label="Bairro"
		// 										value={data.proprietario.endereco.bairro}
		// 										onBlur={handleChangeProprietarioEndereco('bairro')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={4} lg={4}>
		// 									<ZText
		// 										label="Cidade"
		// 										value={data.proprietario.endereco.cidade}
		// 										onBlur={handleChangeProprietarioEndereco('cidade')}
		// 									/>
		// 								</Grid>
		// 								<Grid item xs={12} sm={6} md={2} lg={2}>
		// 									<ZText
		// 										label="Estado"
		// 										value={data.proprietario.endereco.estado}
		// 										onBlur={handleChangeProprietarioEndereco('estado')}
		// 									/>
		// 								</Grid>
		// 								<Grid item sm={6} xs={12} md={6} lg={6}>
		// 									<ZText
		// 										label="Complemento"
		// 										onError={onError}
		// 										value={data.proprietario.endereco.complemento}
		// 										onBlur={handleChangeProprietarioEndereco('complemento')}
		// 									/>
		// 								</Grid>
		// 							</Grid>
		// 						</Grid>
		// 					)}

		// 					<Grid container spacing={5}>
		// 						{!match.params.estabelecimentoId && (
		// 							<Grid item md={5}>
		// 								<Grid container justifyContent="center">
		// 									<Grid item xs={12} style={{ marginTop: 20, marginBottom: 10 }}>
		// 										<Typography variant="h6" gutterBottom>
		// 											Conta Bancária
		// 										</Typography>
		// 										<Divider />
		// 									</Grid>

		// 									<Grid item xs={12} sm={12} md={12}>
		// 										<div className={classes.novaContaBancariaFields}>
		// 											<Grid container spacing={2}>
		// 												<Grid item xs>
		// 													<div
		// 														className={clsx(classes.tipoContaBancaria, {
		// 															selected:
		// 																data.contaBancaria.tipoContaBancaria === 1,
		// 														})}
		// 														onClick={() =>
		// 															setData({
		// 																...data,
		// 																contaBancaria: {
		// 																	...data.contaBancaria,
		// 																	tipoContaBancaria: 1,
		// 																},
		// 															})
		// 														}
		// 													>
		// 														Conta Corrente
		// 													</div>
		// 												</Grid>
		// 												<Grid item xs>
		// 													<div
		// 														className={clsx(classes.tipoContaBancaria, {
		// 															selected:
		// 																data.contaBancaria.tipoContaBancaria === 2,
		// 														})}
		// 														onClick={() =>
		// 															setData({
		// 																...data,
		// 																contaBancaria: {
		// 																	...data.contaBancaria,
		// 																	tipoContaBancaria: 2,
		// 																},
		// 															})
		// 														}
		// 													>
		// 														Conta Poupança
		// 													</div>
		// 												</Grid>
		// 											</Grid>

		// 											<TextField
		// 												className={classes.textField}
		// 												label={data.tipoEstabelecimentoId === 2 ? `CNPJ` : `CPF`}
		// 												disabled={true}
		// 												value={
		// 													data.tipoEstabelecimentoId === 2
		// 														? Mask.Cnpj(data.cnpj || '')
		// 														: Mask.Cpf(data.cpf || '')
		// 												}
		// 											/>

		// 											<TextField
		// 												error={errors.nomeTitular || false}
		// 												className={classes.textField}
		// 												label="Nome"
		// 												value={data.contaBancaria.nomeTitular}
		// 												onChange={e =>
		// 													setData({
		// 														...data,
		// 														contaBancaria: {
		// 															...data.contaBancaria,
		// 															nomeTitular: e.target.value,
		// 														},
		// 													})
		// 												}
		// 											/>

		// 											<FormControl
		// 												error={errors.bancoId || false}
		// 												className={classes.formControl}
		// 											>
		// 												<InputLabel htmlFor="banco">Banco</InputLabel>
		// 												<Select
		// 													value={data.contaBancaria.bancoId}
		// 													onChange={e =>
		// 														setData({
		// 															...data,
		// 															contaBancaria: {
		// 																...data.contaBancaria,
		// 																bancoId: e.target.value,
		// 															},
		// 														})
		// 													}
		// 													inputProps={{ id: 'bancoId' }}
		// 													// disabled={isEdmond && !isModerador}
		// 												>
		// 													{bancos.map((b, i) => (
		// 														<MenuItem key={i} value={b.id}>
		// 															{b.nome}
		// 														</MenuItem>
		// 													))}
		// 												</Select>
		// 											</FormControl>

		// 											<TextField
		// 												error={errors.agencia || false}
		// 												className={classes.textField}
		// 												label="Agência"
		// 												value={data.contaBancaria.agencia}
		// 												onChange={e =>
		// 													setData({
		// 														...data,
		// 														contaBancaria: {
		// 															...data.contaBancaria,
		// 															agencia: e.target.value,
		// 														},
		// 													})
		// 												}
		// 											/>

		// 											<TextField
		// 												error={errors.conta || false}
		// 												className={classes.textField}
		// 												label="Conta"
		// 												value={data.contaBancaria.conta}
		// 												onChange={e =>
		// 													setData({
		// 														...data,
		// 														contaBancaria: {
		// 															...data.contaBancaria,
		// 															conta: e.target.value,
		// 														},
		// 													})
		// 												}
		// 											/>
		// 										</div>
		// 									</Grid>
		// 								</Grid>
		// 							</Grid>
		// 						)}
		// 					</Grid>
		// 				</Grid>

		// 				<Grid container spacing={3}>
		// 					<Grid item xs={12} style={{ marginTop: 40, marginBottom: 10 }}>
		// 						<Typography variant="h6" gutterBottom>
		// 							Entregar POS em um endereço diferente do cadastro?{' '}
		// 							<Switch
		// 								checked={switchBoolean}
		// 								onChange={() => setSwitch(!switchBoolean)}
		// 								inputProps={{ 'aria-label': 'primary checkbox' }}
		// 							/>
		// 						</Typography>
		// 						<Divider />
		// 					</Grid>
		// 					{switchBoolean && (
		// 						<React.Fragment>
		// 							<Grid item xs={12} sm={6} md={2} lg={2}>
		// 								<ZText
		// 									label="CEP"
		// 									onError={onError}
		// 									required={true}
		// 									mask="99999-999"
		// 									value={data.enderecoPOS.cep}
		// 									onBlur={async e => {
		// 										let dataCep = await getCep(e.target.value);
		// 										setData({
		// 											...data,
		// 											enderecoPOS: {
		// 												...data.enderecoPOS,
		// 												cep: Mask.OnlyNumber(dataCep.cep || ''),
		// 												logradouro: dataCep.logradouro,
		// 												bairro: dataCep.bairro,
		// 												cidade: dataCep.localidade,
		// 												estado: dataCep.uf,
		// 											},
		// 										});
		// 									}}
		// 								/>
		// 							</Grid>
		// 							<Grid item xs={12} sm={6} md={1} lg={1}>
		// 								<ZText
		// 									label="Número"
		// 									onError={onError}
		// 									required={true}
		// 									value={data.enderecoPOS.numero}
		// 									onBlur={e =>
		// 										setData({
		// 											...data,
		// 											enderecoPOS: { ...data.enderecoPOS, numero: e.target.value },
		// 										})
		// 									}
		// 								/>
		// 							</Grid>
		// 							<Grid item xs={12} sm={6} md={3} lg={3}>
		// 								<ZText
		// 									label="Logradouro"
		// 									value={data.enderecoPOS.logradouro}
		// 									onBlur={e =>
		// 										setData({
		// 											...data,
		// 											enderecoPOS: { ...data.enderecoPOS, logradouro: e.target.value },
		// 										})
		// 									}
		// 								/>
		// 							</Grid>
		// 							<Grid item xs={12} sm={6} md={3} lg={3}>
		// 								<ZText
		// 									label="Bairro"
		// 									value={data.enderecoPOS.bairro}
		// 									onBlur={e =>
		// 										setData({
		// 											...data,
		// 											enderecoPOS: { ...data.enderecoPOS, bairro: e.target.value },
		// 										})
		// 									}
		// 								/>
		// 							</Grid>
		// 							<Grid item xs={12} sm={6} md={2} lg={2}>
		// 								<ZText
		// 									label="Cidade"
		// 									value={data.enderecoPOS.cidade}
		// 									onBlur={e =>
		// 										setData({
		// 											...data,
		// 											enderecoPOS: { ...data.enderecoPOS, cidade: e.target.value },
		// 										})
		// 									}
		// 								/>
		// 							</Grid>
		// 							<Grid item xs={12} sm={6} md={1} lg={1}>
		// 								<ZText
		// 									label="Estado"
		// 									value={data.enderecoPOS.estado}
		// 									onBlur={e =>
		// 										setData({
		// 											...data,
		// 											enderecoPOS: { ...data.enderecoPOS, estado: e.target.value },
		// 										})
		// 									}
		// 								/>
		// 							</Grid>
		// 							<Grid item sm={6} xs={12} md={3} lg={3}>
		// 								<ZText
		// 									label="Complemento"
		// 									// onError={onError}
		// 									value={data.enderecoPOS.complemento}
		// 									onBlur={e =>
		// 										setData({
		// 											...data,
		// 											enderecoPOS: { ...data.enderecoPOS, complemento: e.target.value },
		// 										})
		// 									}
		// 								/>
		// 							</Grid>
		// 						</React.Fragment>
		// 					)}
		// 				</Grid>

		// 				<Grid item xs={12}>
		// 					<Grid
		// 						container
		// 						direction="row"
		// 						justifyContent="flex-end"
		// 						alignItems="center"
		// 						style={{ marginTop: 25 }}
		// 					>
		// 						<ZSaveButton
		// 							onClick={() => handleSubmitForm()}
		// 							color={'primary'}
		// 							align="right"
		// 							label={'Salvar'}
		// 							required={true}
		// 							loading={loading}
		// 						/>
		// 					</Grid>
		// 				</Grid>
		// 			</Paper>
		// 		</Grid>
		// 	</Grid>
		// </Grid>
		<Grid>
			<Grid className={classes.conteudo} container spacing={1} display="flex" direction="column">
				<Grid item>
					{[5, 8].includes(usuario.grupo_id) ? (
						<Typography className={classes.titulo} variant="h4">
							Pré Cadastro de Estabelecimento
						</Typography>
					) : (
						<Typography className={classes.titulo} variant="h4">
							{!match.params.estabelecimentoId ? 'Cadastrar Estabelecimento' : 'Editar Estabelecimento'}
						</Typography>
					)}
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={10} md={6} className={classes.segundoStep}>
						{step === 1 && (
							<>
								<Grid item xs={12} style={{ marginBottom: 16 }}>
									<Typography variant="h6">Informações do estabelecimento.</Typography>
								</Grid>
								<Grid container spacing={4}>
									{![5, 8].includes(usuario.grupo_id) && (
										<Grid item xs={12} style={{ marginBottom: 10 }}>
											<ZSwitch
												onChange={handleDesativarVendas}
												label={`Desativar Vendas Desse Estabelecimento?`}
												// labelPlacement="top"
												checked={data.desativarVendas}
											/>
										</Grid>
									)}
									<Grid item xs={12} md={6}>
										<ZSelect
											options={[
												{
													id: 1,
													name: 'Pessoa Física',
												},
												{
													id: 2,
													name: 'Pessoa Jurídica',
												},
											]}
											label="Tipo"
											required={true}
											value={data.tipoEstabelecimentoId}
											onChange={handleTipoEstabelecimento('tipoEstabelecimentoId')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl style={{ marginTop: 15, width: '100%' }}>
											<InputLabel>Categoria</InputLabel>
											<Select
												value={categorias.length > 0 ? data.categoria : ''}
												onChange={e => {
													const selectedCategory = categorias.find(
														categoria => categoria.id === e.target.value
													);
													setData({
														...data,
														categoria: e.target.value,
														descricao_categoria: selectedCategory
															? selectedCategory.description
															: '',
													});
												}}
												required={true}
											>
												<MenuItem value="">
													<em>Categoria</em>
												</MenuItem>
												{categorias.map((categoria, i) => {
													if (!categoria.id) {
														return (
															<ListSubheader key={i}>
																{categoria.description}
															</ListSubheader>
														);
													}

													return (
														<MenuItem key={i} value={categoria.id}>
															{categoria.description}
														</MenuItem>
													);
												})}
											</Select>
											{onError && !data.categoria && (
												<div style={{ color: 'red' }}>O campo não pode ser vazio.</div>
											)}
										</FormControl>
									</Grid>

									{data.tipoEstabelecimentoId === 1 ? (
										<>
											<Grid item xs={12} md={6}>
												<ZText
													onError={onError}
													required={true}
													value={data.nome}
													onBlur={handleChange('nome')}
													label="Nome Completo"
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Data Nascimento"
													onError={onError}
													required={true}
													value={data.dataNascimento2}
													mask="99/99/9999"
													onBlur={handleDataNascimento('dataNascimento2')}
												/>
											</Grid>
										</>
									) : (
										<>
											<Grid item xs={12} md={6}>
												<ZText
													onError={onError}
													required={true}
													value={data.razaoSocial}
													onBlur={handleChange('razaoSocial')}
													label="Razão Social"
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Nome Fantasia"
													onError={onError}
													required={true}
													value={data.nomeFantasia}
													onBlur={handleChange('nomeFantasia')}
												/>
											</Grid>
										</>
									)}
									<Grid item xs={12} md={6}>
										<ZText
											label="E-mail"
											onError={onError}
											required={true}
											type="email"
											email={true}
											value={data.email}
											onBlur={handleChange('email')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										{data.tipoEstabelecimentoId === 1 ? (
											<>
												<ZText
													onError={onError}
													required={true}
													mask="999.999.999-99"
													value={data.cpf}
													onBlur={handleChange('cpf')}
													label="CPF"
												/>
											</>
										) : (
											<>
												<ZText
													onError={onError}
													required={true}
													mask="99.999.999/9999-99"
													value={data.cnpj}
													onBlur={handleChange('cnpj')}
													label="CNPJ"
												/>
											</>
										)}
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Celular"
											onError={onError}
											required={true}
											value={data.celular}
											mask="(99) 9 9999-9999"
											onBlur={handleChange('celular')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.nomeComprovante}
											onBlur={handleChange('nomeComprovante')}
											label="Nome Comprovante"
										/>
									</Grid>
									{data.tipoEstabelecimentoId === 2 && (
										<>
											<Grid item xs={12} md={6}>
												<ZText
													label="Telefone"
													onError={onError}
													required={true}
													value={data.telefone}
													mask="(99) 9999-9999"
													onBlur={handleChange('telefone')}
												/>
											</Grid>
										</>
									)}
									<Grid item xs={12} md={6}>
										<ZText
											label={
												match.params.estabelecimentoId
													? 'Plano inicial'
													: 'Identificador do plano'
											}
											onError={onError}
											disabled={match.params.estabelecimentoId ? true : false}
											required={false}
											value={data.identificadorPlano || ''}
											onBlur={handleChange('identificadorPlano')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label={'Quantidade de POS'}
											// onError={onError}
											// disabled={match.params.estabelecimentoId ? true : false}
											// required={false}
											value={data.quantidade_pos}
											onBlur={e => {
												setData({
													...data,
													quantidade_pos: Mask.OnlyNumber(e.target.value),
												});
											}}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label={'Faturamento estimado'}
											onError={onError}
											// disabled={match.params.estabelecimentoId ? true : false}
											required={true}
											min={1}
											value={Mask.Real(data.faturamento_estimado || 0)}
											onChange={value =>
												setData({
													...data,
													faturamento_estimado: Mask.OnlyNumber(value) / 100,
												})
											}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label={'Observações'}
											type={'textarea'}
											// onError={onError}
											// disabled={match.params.estabelecimentoId ? true : false}
											// required={false}
											value={data.observacao}
											onBlur={e => {
												setData({ ...data, observacao: e.target.value });
											}}
										/>
									</Grid>
								</Grid>
							</>
						)}
						{step === 2 && (
							<>
								<Typography variant="h6">Preencha seu endereço.</Typography>
								<Grid container spacing={4}>
									<Grid item xs={12} md={6}>
										<ZText
											label="CEP"
											onError={onError}
											required={true}
											mask="99999-999"
											value={data.endereco.cep}
											onBlur={handleChangeEndereco('cep')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Endereço"
											onError={onError}
											required={true}
											value={data.endereco.logradouro}
											onBlur={handleChangeEndereco('logradouro')}
										/>
									</Grid>
									<Grid item xs={12} md={3}>
										<ZText
											label="Número"
											onError={onError}
											required={true}
											value={data.endereco.numero}
											onBlur={handleChangeEndereco('numero')}
										/>
									</Grid>
									<Grid item xs={12} md={9}>
										<ZText
											onError={onError}
											value={data.endereco.complemento}
											onBlur={handleChangeEndereco('complemento')}
											label="Complemento"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.endereco.cidade}
											onBlur={handleChangeEndereco('cidade')}
											label="Cidade"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.endereco.estado}
											onBlur={handleChangeEndereco('estado')}
											label="UF"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.endereco.bairro}
											onBlur={handleChangeEndereco('bairro')}
											label="Bairro"
										/>
									</Grid>
								</Grid>
							</>
						)}
						{step === 3 && (
							<>
								<Typography variant="h6">Preencha os dados do proprietário.</Typography>
								<Grid container spacing={4}>
									<Grid item xs={12} md={6}>
										<ZText
											label="Nome"
											onError={onError}
											required={true}
											value={data.proprietario.nome}
											onBlur={handleProprietario('nome')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Sobrenome"
											onError={onError}
											required={true}
											value={data.proprietario.sobrenome}
											onBlur={handleProprietario('sobrenome')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="E-mail"
											onError={onError}
											required={true}
											type="email"
											email={true}
											value={data.proprietario.email}
											onBlur={handleProprietario('email')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Data Nascimento"
											onError={onError}
											required={true}
											value={data.proprietario.dataNascimento}
											mask="99/99/9999"
											onBlur={handleProprietario('dataNascimento')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Celular"
											onError={onError}
											required={true}
											mask="(99) 9 9999-9999"
											value={data.proprietario.celular}
											onBlur={handleProprietario('celular')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											mask="999.999.999-99"
											label="CPF"
											onError={onError}
											required={true}
											value={data.proprietario.cpf}
											onBlur={handleProprietario('cpf')}
										/>
									</Grid>
								</Grid>
							</>
						)}
						{step === 4 && (
							<>
								<Typography variant="h6">Preencha os dados residencias do proprietário.</Typography>
								<Grid container spacing={4}>
									<Grid item xs={12} md={6}>
										<ZText
											label="CEP"
											onError={onError}
											required={true}
											mask="99999-999"
											value={data.proprietario.endereco.cep}
											onBlur={handleChangeProprietarioEndereco('cep')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Endereço"
											onError={onError}
											required={true}
											value={data.proprietario.endereco.logradouro}
											onBlur={handleChangeProprietarioEndereco('logradouro')}
										/>
									</Grid>
									<Grid item xs={12} md={3}>
										<ZText
											label="Número"
											onError={onError}
											required={true}
											value={data.proprietario.endereco.numero}
											onBlur={handleChangeProprietarioEndereco('numero')}
										/>
									</Grid>
									<Grid item xs={12} md={9}>
										<ZText
											onError={onError}
											value={data.proprietario.endereco.complemento}
											onBlur={handleChangeProprietarioEndereco('complemento')}
											label="Complemento"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.proprietario.endereco.cidade}
											onBlur={handleChangeProprietarioEndereco('cidade')}
											label="Cidade"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.proprietario.endereco.estado}
											onBlur={handleChangeProprietarioEndereco('estado')}
											label="UF"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.proprietario.endereco.bairro}
											onBlur={handleChangeProprietarioEndereco('bairro')}
											label="Bairro"
										/>
									</Grid>
								</Grid>
							</>
						)}
						{step === 5 && (
							<>
								<Typography variant="h6" gutterBottom>
									Entregar POS em um endereço diferente do cadastro?{' '}
									<Switch
										checked={switchBoolean}
										onChange={() => setSwitch(!switchBoolean)}
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
								</Typography>
								{switchBoolean && (
									<>
										<Grid container spacing={4}>
											<Grid item xs={12} md={6}>
												<ZText
													label="CEP"
													onError={onError}
													required={true}
													mask="99999-999"
													value={data.enderecoPOS.cep}
													onBlur={async e => {
														let dataCep = await getCep(e.target.value);
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																cep: Mask.OnlyNumber(dataCep.cep || ''),
																logradouro: dataCep.logradouro,
																bairro: dataCep.bairro,
																cidade: dataCep.localidade,
																estado: dataCep.uf,
															},
														});
													}}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Número"
													onError={onError}
													required={true}
													value={data.enderecoPOS.numero}
													onBlur={e =>
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																numero: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Logradouro"
													value={data.enderecoPOS.logradouro}
													onBlur={e =>
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																logradouro: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Bairro"
													value={data.enderecoPOS.bairro}
													onBlur={e =>
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																bairro: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Cidade"
													value={data.enderecoPOS.cidade}
													onBlur={e =>
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																cidade: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Estado"
													value={data.enderecoPOS.estado}
													onBlur={e =>
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																estado: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Complemento"
													// onError={onError}
													value={data.enderecoPOS.complemento}
													onBlur={e =>
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																complemento: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={6}></Grid>
										</Grid>
									</>
								)}
							</>
						)}
						{step === 6 && !match.params.estabelecimentoId && (
							<>
								<Typography variant="h6">Preencha seus dados bancários.</Typography>
								<Grid container spacing={4}>
									<Grid container flex spacing={4} item xs={12}>
										<Grid item xs={6}>
											<div
												className={clsx(classes.tipoContaBancaria, {
													selected: data.contaBancaria.tipoContaBancaria === 1,
												})}
												onClick={() =>
													setData({
														...data,
														contaBancaria: {
															...data.contaBancaria,
															tipoContaBancaria: 1,
														},
													})
												}
											>
												Conta Corrente
											</div>
										</Grid>
										<Grid item xs={6}>
											<div
												className={clsx(classes.tipoContaBancaria, {
													selected: data.contaBancaria.tipoContaBancaria === 2,
												})}
												onClick={() =>
													setData({
														...data,
														contaBancaria: {
															...data.contaBancaria,
															tipoContaBancaria: 2,
														},
													})
												}
											>
												Conta Poupança
											</div>
										</Grid>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label="Nome"
											className={classes.textField}
											value={data.contaBancaria.nomeTitular}
											onChange={e =>
												setData({
													...data,
													contaBancaria: {
														...data.contaBancaria,
														nomeTitular: e.target.value,
													},
												})
											}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label={data.tipoEstabelecimentoId === 2 ? `CNPJ` : `CPF`}
											disabled={true}
											className={classes.textField}
											value={
												data.tipoEstabelecimentoId === 2
													? Mask.Cnpj(data.cnpj || '')
													: Mask.Cpf(data.cpf || '')
											}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl
											//error={errors.bancoId || false}
											className={classes.formControl}
										>
											<InputLabel htmlFor="banco">Banco</InputLabel>
											<Select
												value={data.contaBancaria.bancoId}
												className={classes.textField}
												onChange={e =>
													setData({
														...data,
														contaBancaria: {
															...data.contaBancaria,
															bancoId: e.target.value,
														},
													})
												}
												inputProps={{ id: 'bancoId' }}
											>
												{bancos.map((b, i) => (
													<MenuItem key={i} value={b.id}>
														{b.nome}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label="Agência"
											className={classes.textField}
											value={data.contaBancaria.agencia}
											onChange={e =>
												setData({
													...data,
													contaBancaria: {
														...data.contaBancaria,
														agencia: e.target.value,
													},
												})
											}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											error={errors.conta || false}
											className={classes.textField}
											label="Conta"
											value={data.contaBancaria.conta}
											onChange={e =>
												setData({
													...data,
													contaBancaria: {
														...data.contaBancaria,
														conta: e.target.value,
													},
												})
											}
										/>
									</Grid>
								</Grid>
							</>
						)}
						<Grid item style={{ marginTop: '16px' }} xs={12}>
							<Grid container spacing={4}>
								<Grid item xs={12} md={6}>
									{step !== 1 && (
										<Button
											fullWidth
											size="large"
											className={classes.botao}
											onClick={
												data.tipoEstabelecimentoId === 1 && step === 5
													? () => setStep(step - 3)
													: () => setStep(step - 1)
											}
											variant="contained"
										>
											Voltar
										</Button>
									)}
								</Grid>
								<Grid item xs={12} md={6}>
									{step === 6 || (step === 5 && match.params.estabelecimentoId) ? (
										<ZSaveButton
											onClick={
												[5, 8].includes(usuario.grupo_id)
													? () => handleSubmitFormPreCadastro()
													: () => handleSubmitForm()
											}
											color={'primary'}
											align="right"
											label={'Salvar'}
											required={true}
											loading={loading}
										/>
									) : (
										<Button
											fullWidth
											size="large"
											className={classes.botao}
											onClick={
												data.tipoEstabelecimentoId === 1 && step === 2
													? () => setStep(step + 3)
													: () => setStep(step + 1)
											}
											variant="contained"
										>
											Continuar
										</Button>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
